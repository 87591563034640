import React from 'react';
import { Box, HStack, Text, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Link, Badge, Input, Select, Image } from '@chakra-ui/react'
import logo_img from '../assets/images/logo_img.png'
import micro_icon from '../assets/images/micro_icon.png'
import phone_icon from '../assets/images/phone_icon.png'
import gala_add from '../assets/images/gala_add.png'
import jam_screen from '../assets/images/jam_screen.png'
import users_icon from '../assets/images/users_icon.png'
import bi_chat from '../assets/images/bi_chat.png'
import whatsapp_icon from '../assets/images/whatsapp_icon.png'
import bi_book from '../assets/images/bi_book.png'
import rewind_time_icon from '../assets/images/rewind_time_icon.png'
import formkit_email from '../assets/images/formkit_email.png'
import pie_chart_icon from '../assets/images/pie_chart_icon.png'
import image_circle_icon from '../assets/images/image_circle_icon.png'
import iconoir_settings from '../assets/images/iconoir_settings.png'
import down_icon from '../assets/images/down_icon.png'
import user_img from '../assets/images/user_img.png'
import { FaAngleDown } from "react-icons/fa";

function Header(){
	return(
		<>
			<Box className="header_section">
				<HStack justifyContent="space-between">
					<Box className="header_left_section">
						<HStack justifyContent="space-between">
							<Box>
								<HStack>
									<Box>
										<Image className="logo_img" src={logo_img} />
									</Box>

									<Box className="search_field_section">
										<Input placeholder="Search or dial a number" />

										<Image src={micro_icon} />

										<Button className="phone_btn">
											<Image src={phone_icon} />
										</Button>
									</Box>

									<Box>
										<Text className="web_text"><FaAngleDown /> Web</Text>
									</Box>
								</HStack>
							</Box>

							<Box>
								<HStack>
									<Box width="40px" height="40px">
										<Image src={gala_add} />
									</Box>

									<Box ml="10px">
										<Box className="sam_screen_bg">
											<Image src={jam_screen} />
										</Box>
									</Box>
								</HStack>
							</Box>

							<Box>
								<HStack>
									<Box me="10px">
										<Image src={users_icon} />
									</Box>

									<Box me="10px">
										<Image src={bi_chat} />
									</Box>

									<Box me="10px">
										<Image src={whatsapp_icon} />
									</Box>

									<Box me="10px">
										<Image src={bi_book} />
									</Box>

									<Box me="10px">
										<Image src={rewind_time_icon} />
									</Box>

									<Box me="10px">
										<Image src={formkit_email} />
									</Box>

									<Box me="10px">
										<Image src={pie_chart_icon} />
									</Box>

									<Box me="10px">
										<Image src={image_circle_icon} />
									</Box>

									<Box me="10px">
										<Image src={iconoir_settings} />
									</Box>

									<Box me="10px">
										<Image src={down_icon} />
									</Box>
								</HStack>
							</Box>
						</HStack>
					</Box>

					<Box className="header_right_section">
						<HStack>
							<Box className="user_img_box">
								<Image src={user_img} />
							</Box>

							<Box className="text_section">
								<Text className="username_text">Syed Zain ul abideen (100)</Text>
								<Text className="para_text">67GM+XPF - Trade Center - Trade Ce...</Text>
							</Box>

							<Box>
								<FaAngleDown />
							</Box>
						</HStack>
					</Box>
				</HStack>
			</Box>
		</>
	)
}

export default Header;