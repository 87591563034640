import React from 'react'
import { Box, HStack, Text, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Link, Image } from '@chakra-ui/react'
import { FaRegClock, FaCheckCircle, FaHome, FaUser, FaEnvelope, FaBirthdayCake, FaPhoneAlt, FaInfoCircle, FaAngleRight } from "react-icons/fa";
import whatsapp_icon_img from '../assets/images/whatsapp_icon_img.png'

function DetailLeftBar(){
	return(
		<>
			<Box className="detail_leftbar_section">
				<HStack className="detail_name_section">
					<Box className="profile_box_section">
						<Text>R</Text>

						<Image className="icon_img" src={whatsapp_icon_img} />
					</Box>

					<Box className="text_section">
						<Heading>RK</Heading>
						<Text>NCe3Z8nHCJxio7eozf7JFShdJABqbwZC</Text>
					</Box>
				</HStack>

				<Box className="tabs_section">
					<Tabs variant='soft-rounded' colorScheme='green'>
					  <TabList className="tabs_head_section">
					    <Tab><FaRegClock /></Tab>
					    <Tab><FaRegClock /></Tab>
					  </TabList>
					  <TabPanels className="tabs_body_section">
					    <TabPanel p="10px">
					      <Box className="basic_detail_section">
					      	<HStack justifyContent="space-between" mb="15px">
					      		<Box>
					      			<Text className="head_text">Basic Details</Text>
					      		</Box>

					      		<Box>
					      			<HStack>
					      				<Text>Sync</Text>

					      				<FaCheckCircle className="check_icon" />
					      			</HStack>			
					      		</Box>
					      	</HStack>

					      	<HStack mb="12px">
					      		<Box me="10px">
					      			<FaPhoneAlt />
					      		</Box>

					      		<Box>
					      			<Text>9234526273202</Text>
					      		</Box>
					      	</HStack>

					      	<HStack mb="12px">
					      		<Box me="10px">
					      			<FaEnvelope />
					      		</Box>

					      		<Box>
					      			<Text>rehmat.wajahat@eocean.com.pk</Text>
					      		</Box>
					      	</HStack>

					      	<HStack mb="12px">
					      		<Box me="10px">
					      			<FaBirthdayCake />
					      		</Box>

					      		<Box>
					      			<Text>Dec 01, 2021</Text>
					      		</Box>
					      	</HStack>

					      	<HStack mb="12px">
					      		<Box me="10px">
					      			<FaUser />
					      		</Box>

					      		<Box>
					      			<Text></Text>
					      		</Box>
					      	</HStack>

					      	<HStack mb="12px">
					      		<Box me="10px">
					      			<FaHome />
					      		</Box>

					      		<Box>
					      			<Text>North Nazimabad</Text>
					      		</Box>
					      	</HStack>

					      	<Button className="btn_primary">Add Alternate Number</Button>
					      </Box>
					    </TabPanel>
					    <TabPanel>
					      <p>two!</p>
					    </TabPanel>
					  </TabPanels>
					</Tabs>
				</Box>

				<Link className="link_section">
					<HStack justifyContent="space-between">
						<Box>
							<HStack>
								<Text>Customer Profile</Text>

								<FaInfoCircle />
							</HStack>
							
						</Box>

						<Box>
							<FaAngleRight color="#000" size="25px" />
						</Box>
					</HStack>
				</Link>

				<Link className="link_section">
					<HStack justifyContent="space-between">
						<Box>
							<HStack>
								<Text>Tags</Text>

								<FaInfoCircle />
							</HStack>
							
						</Box>

						<Box>
							<FaAngleRight color="#000" size="25px" />
						</Box>
					</HStack>
				</Link>

				<Link className="link_section">
					<HStack justifyContent="space-between">
						<Box>
							<HStack>
								<Text>Notes</Text>

								<FaInfoCircle />
							</HStack>
							
						</Box>

						<Box>
							<FaAngleRight color="#000" size="25px" />
						</Box>
					</HStack>
				</Link>
			</Box>
		</>
	)
}

export default DetailLeftBar;