import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatScreen from './pages/ChatScreen'
import Login from './pages/auth/Login'
import { PrivateRoute } from './components/PrivateRoute';
function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
          <Routes>  
            <Route path="/" element={<Login />} />

            <Route path="/chat" element=
            <PrivateRoute>
              {<ChatScreen/>}
            </PrivateRoute>
               />
            
            

          </Routes>
        </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
