
import React,{useState} from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import logo from '../../assets/images/chatbot_img.png';
import * as Yup from 'yup';
import { Link ,useNavigate} from "react-router-dom";
import {InputText,Form} from '../../components/form';

import {
  
  Button
} from '@chakra-ui/react';
import { fetchWrapper } from '../../helpers';

function Login(){
	const navigate  = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    
    
  });
  const FormSchema = Yup.object().shape({
       username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
       
  });
  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/auth/login-web`;
   	   const loginData = await fetchWrapper.post(url,data)

       //const token = {token:loginData.accessToken,name:loginData.FULL_NAME,login_id:LOGIN_ID}
       localStorage.setItem('user',JSON.stringify(loginData))

        navigate("/chat");
        // console.log(loginData)
  }
	return(
		<>
			<HStack bg="#fff" h="100vh" justifyContent="center" flexDirection="column">
		      

		      <Box borderRadius="5px" width="481px" bg="#fff" py="30" px="25" borderWidth="1px" borderColor="#ccc" borderStyle="solid">
		      	<Box mb="30px">
			        <Image m="auto" src={logo} alt='Gun Guru' />
		      	</Box>

		      	<Text textAlign="center" color="#000" mb="20px" fontSize="16px" fontWeight="400" fontFamily="'Montserrat', sans-serif">Please login into your account</Text>
		      	<Form
					enableReinitialize
	              	validationSchema={FormSchema}
	              	initialValues={formData}
	              	onSubmit ={onSubmit}    
		       	>
		           <InputText name="username" title="User Name"  />
		           <InputText inputProps={{type:'password'}} name="password" title="Password"  />


		           <Button type="submit" bg="#31367A" borderColor="#31367A" borderRadius="5px" fontSize="14px" fontWeight="500" color="#fff" width="100%" p="15" borderWidth="1px" borderStyle="solid">Login</Button>
		       </Form>
		        

		        
		      </Box>
		    </HStack>
		</>
	)
}

export default Login;